import { defineStore } from "pinia";
import { useSharedStore } from "@/entrypoints/stores/shared";
import crypto from "@/entrypoints/shared/aion_crypto";
import apiClient from "@/entrypoints/frontend/apiClient";
import { reactive, ref } from "vue";
import type { SignUpVoterParticipation } from "@/types";

export const useSignUpSessionStore = defineStore("signUpSession", () => {
  const sharedStore = useSharedStore();

  // State
  const error = ref<string>(null);
  const privateKey = ref<string>(null);
  const publicKey = ref<string>(null);
  const signature = ref<string>(null);
  const answered = ref<boolean>(null);
  const voter = reactive<SignUpVoterParticipation>({
    name: null,
    participating: null,
  });

  async function authenticate(electionCodes: string[]) {
    error.value = null;

    const privateKey = electionCodes.map((code: string) =>
      crypto.electionCodeToPrivateKey(code, "pbkdf2")).reduce(crypto.addBigNums);
    const publicKey = crypto.generateKeyPair(privateKey).public_key;
    const signature = crypto.generateSchnorrSignature("", privateKey);

    try {
      const response = await apiClient.post(`${sharedStore.electionUrl}/authenticate_sign_up`, {
        public_key: publicKey,
        signature: signature,
      });

      const data = await response.data;

      startSession(data.voter, {privateKey, publicKey, signature})

    } catch (err) {
      if (err.response) error.value = err.response.data.error;
    }
  }

  const submitAnswer = async (participation: boolean) => {
    try {
      const response = await apiClient.put(`${sharedStore.electionUrl}/submit_sign_up`, {
        signature: signature.value,
        public_key: publicKey.value,
        participating: participation,
      });

      const data = await response.data;

      if (data.voter) {
        voter.participating = data.voter.participating;
        answered.value = true;
      }
    } catch (error) {
      destroySession();
      if (error.response) {
        error.value = error.response.data.error;
      }
    }
  }

  function destroySession() {
    privateKey.value = null;
    publicKey.value = null;
    signature.value = null;
    answered.value = null;
    voter.name = null;
    voter.participating = null;
  }

  function startSession({ name, participating }, { privateKey: pks, publicKey: pkp, signature: sigt }) {
    voter.name = name;
    voter.participating = participating;
    privateKey.value = pks;
    publicKey.value = pkp;
    signature.value = sigt;
    answered.value = participating !== null;
  }

  return {
    error,
    answered,
    voter,
    publicKey,
    authenticate,
    submitAnswer,
    destroySession,
  }
});